.loader {
  position: fixed;
  top: 50%;
  right: 50%;
  width:  100px;
  height: 100px;
  background: #00A398;
  transform: rotateX(65deg) rotate(45deg);
  // remove bellows command for perspective change
  //transform: perspective(200px) rotateX(65deg) rotate(45deg); 
  color: #1C5858;
  animation: layers1 1s linear infinite alternate;
  z-index: 1;
}
.loader:after {
  content: '';
  position: absolute;
  inset: 0;
  background: rgba(174, 248, 236, 0.7);
  animation: layerTr 1s linear infinite alternate;
}

@keyframes layers1 {
  0% { box-shadow: 0px 0px 0 0px  }
 90% , 100% { box-shadow: 30px 30px 0 -4px  }
}
@keyframes layerTr {
  0% { transform:  translate(0, 0) scale(1) }
  100% {  transform: translate(-35px, -35px) scale(1) }
}
    